import { IModal } from '@/components';
import Modal from '@/components/modal.vue';
import { ITable } from '@/components/table';
import Table from '@/components/table/table.vue';
import router from '@/router';
import store, { IState } from '@/store';
import { IStateDeviceModel } from '@/store/modules/deviceModels';
import { IDeviceModelsTranslations } from '@/translations';
import sort from '@/utils/sort';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter, Mutation } from 'vuex-class';

import { table } from './DeviceModels.data';

@Component({
  name: 'DeviceModels',
  components: { Table, Modal }
})
class DeviceModels extends Vue {
  @Action('fetchDeviceModels') public fetch!: () => void;

  @Mutation('selectDeviceModel') public select!: (deviceModel: IStateDeviceModel) => void;

  @Getter('getDeviceModelsTranslations') public translations!: IDeviceModelsTranslations;
  public table = {} as ITable<IStateDeviceModel>;
  public modal: IModal = {
    isActive: true,
    isLarge: true,
    close: () => {
      router.push('/devices');
    }
  }; public created() {

    this.fetch();

    this.table = table(this.translations);
    this.table.onSelectedItem = this.onDeviceModelSelected;

    store.subscribe((mutation: MutationPayload, state: IState) => {

      if (mutation.type !== 'selectDeviceModel') {

        if (mutation.type === 'setLanguage') {

          this.table = { ...this.table, ...table(this.translations) };

        }

        const key = Object.keys(this.table.order) as Array<Extract<IStateDeviceModel, string>>;

        const field = key[0];
        const order = this.table.order[key[0]];

        this.table = {
          ...this.table,
          data: sort(field, order, state.deviceModels.list)
        };

      }

    });

  }
  public onDeviceModelSelected(deviceModel: IStateDeviceModel) {
    this.select(deviceModel);
  }
  public close() {
    router.push('/devices/models');
  }
}

export default DeviceModels;
